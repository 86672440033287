import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Real Estate Website Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/real-estate-website/"
    metaDescription="This template for real estate website development features a gorgeous design with a high-end vibe, suited to make any set of properties feel luxurious."
    description="
    h2:Introducing Estate: A real estate website design template to help launch your brand
    <br/>
    Our real estate website design template, Estate, is designed to give you all you need to begin designing your own real estate website. As with all our templates, Estate can be used straight out of the box, or it can be completely customized to make your <a:https://uizard.io/prototyping/>UI prototyping</a> experience truly unique. Estate is a great way to promote your services in a simple yet compelling way and comes packed with everything you could possibly need to kick start your website design.
    <br/>
    h3:A gorgeous and sleek design with real potential
    <br/>
    Not only does estate include all the must-have UX considerations for a real estate website, but it also features a gorgeous and sleek design with a high-end vibe, suited to make any set of properties feel luxurious. Whether you are working alone or working with a team, designing a website for your real estate business has never been so easy.
    <br/>
    h3:What Uizard brings to the table as your next real estate website builder
    <br/>
    Using Uizard as your next real estate website design tool allows you to effortlessly design a website featuring a landing page, listing page, about page, and much more. Uizard <a:https://uizard.io/templates/website-templates/>website design templates</a> enable you to easily showcase, not only your properties, but also your team and your blog.
    "
    pages={[
      "A home page/landing page",
      "A property listing page",
      "A details page dedicated to an individual listing",
      "An about page",
      "A contact page",
      "A blog page",
      "An article page",
    ]}
    projectCode="0olRXP7471Ux6x4qLa7y"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Real Estate Website: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Real Estate Website: listing details page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Real Estate Website: blog page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Real Estate Website: blog article page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Real Estate Website template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/real-estate-website/estate-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/real-estate-website/estate-detail.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/real-estate-website/estate-blog.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/real-estate-website/estate-article.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/real-estate-website/estate-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
